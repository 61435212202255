import { useState } from 'react';
import ReactCalendar from 'react-calendar';

import { Container, ContainerButton } from './styles';

import '../../styles/calendar.css';

interface CalendarProps {
  onConfirm: (date: Date | Date[]) => void;
  onClose: () => void;
  onClean: () => void;
  isCalendarOpen: boolean;
}

export function Calendar({
  onConfirm,
  onClose,
  onClean,
  isCalendarOpen,
}: CalendarProps) {
  const [selectedDate, setSelectedDate] = useState<Date | Date[]>(new Date());

  return (
    <>
      {isCalendarOpen && (
        <Container>
          <ReactCalendar
            onChange={setSelectedDate}
            value={selectedDate}
            locale='pt-br'
            prev2Label=''
            next2Label=''
            selectRange
          />

          <ContainerButton>
            <button onClick={onClean}>LIMPAR</button>
            <button onClick={onClose}>CANCELAR</button>
            <button onClick={() => onConfirm(selectedDate)}>OK</button>
          </ContainerButton>
        </Container>
      )}
    </>
  );
}
