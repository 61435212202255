import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;

  z-index: 999;

  display: flex;
  flex-direction: column;

  top: 100%;
  right: 50%;

  width: 350px;

  background: #fff;

  border: 1px solid #a0a096;
`;

export const ContainerButton = styled.div`
  display: flex;

  justify-content: space-between;

  margin: 10px 0;

  button {
    padding: 15px;

    color: #7c4dff;

    border: none;

    &:hover {
      color: #7c4dff81;
    }
  }
`;
