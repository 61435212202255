export const STAGE = {
  development: {
    apiUrl:
      'https://502f-2804-e1c-ff0f-ee00-c02f-bdcd-5daa-9115.ngrok.io/api/v1',
  },
  homologation: {
    apiUrl: 'https://api.homologacao.recyclenoapp.com.br/api/v1',
  },
  production: {
    apiUrl: 'https://api.recyclenoapp.com.br/api/v1',
  },
};

export const API_URL = STAGE.homologation.apiUrl;
