import { Switch } from 'react-router-dom';
import { GeneralSettings } from '../pages/GeneralSettings';
import { Indicators } from '../pages/Indicators';
import { Reports } from '../pages/Reports';
import { SignIn } from '../pages/Signin';
import { Wastes } from '../pages/Wastes';
import { UserConfirmation } from '../pages/UserConfirmation';
import { Route } from './Route';

export const Routes = () => (
  <Switch>
    <Route path='/' exact component={SignIn} />
    <Route
      path='/email-confirmation/:confirmation_hashcod/'
      exact
      component={UserConfirmation}
    />
    <Route isPrivate path='/indicators' exact component={Indicators} />
    <Route isPrivate path='/reports' exact component={Reports} />
    <Route isPrivate path='/wastes' exact component={Wastes} />
    <Route
      isPrivate
      path='/general_settings'
      exact
      component={GeneralSettings}
    />
  </Switch>
);
